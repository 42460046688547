@import url('/src/styles/variables.scss');

body {
    .ant-message-notice-content {
        background: $primary-bg;
        color: $primary-color;
        border: 1px solid $border-color;

        i {
            color: $primary-color !important;
        }
    }
}