@import '/src/styles/variables.scss';

body {
    .ant-slider {
        .ant-slider-mark-text {
            color: $primary-color;
            font-size: 12px;
            text-wrap: nowrap;
        }

        .ant-slider-track {
            background-color: #2e9668;
        }

        .ant-slider-handle {
            background-color: #2e9668;
        }

        .ant-slider-mark-text-active {
            color: "white";
        }

        .ant-slider-dot {
            // border-radius: 0;
        }

        .ant-slider-handle {
            // border-radius: 0;
        }

        &:hover {
            .ant-slider-track {
                background-color: #2e9668;
            }
        }
    }
}