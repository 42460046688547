@import url('/src/styles/variables.scss');


body {
    .ant-modal-content {
        background: $primary-bg !important;
        margin-top: -25px;
        .ant-modal-body {
            background: $secondary-bg;
            border-color: $border-color;
        }

        .ant-modal-header {
            background: $secondary-bg;
            border-color: $border-color;

            .ant-modal-title {
                color: $primary-color;
            }
        }

        .ant-modal-close-x {
            color: $primary-color;
        }

    }
}