@import '/src/styles/variables.scss';
ul.ant-card-actions {
    background: $secondary-bg ;
    border: none;
}
.ant-card-actions > li {
    
    color: $primary-color !important;
}

.ant-card.ant-card-bordered {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ant-card-body {
    margin-bottom: auto;
    padding: 0 !important;
}

//  div.ant-tabs-tabpane.ant-tabs-tabpane-active.tab-pane > div.ant-card.ant-card-bordered > div.ant-card-body {
//     padding: 24px 24px 0px 24px;
// }