@import '/src/styles/variables.scss';

body {
    .executions-dropdown {
        width: 400px;
        padding: 10px;
        background: #242730;

        .ant-dropdown-menu {
            background: #29323c;

            .ant-select-selection {
                border: 1px solid #3b4652;
                background: #29323c;
                border-radius: 0;
            }

            .ant-select-arrow {
                color: #adaeb0;
            }
        }
    }

    .ant-select {
        color: #bdbdbd;
    }

    .ant-select-dropdown {
        overflow-y: scroll;

        ::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: $scrollbar-track-color;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $scrollbar-thumb-color;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: $scrollbar-thumb-color;
        }



    }

    .ant-select-tree-dropdown .ant-select-dropdown-search {
        background: transparent;

        .ant-select-search__field {
            background: #29323c;
            color: #adaeb0;
            border: 1px solid #3b4652;
        }
    }

    .ant-select-tree {
        .ant-select-tree-title {
            color: #adaeb0 !important;
        }
    }

    .ant-select-tree-switcher {
        color: #adaeb0;
    }

    .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
        background-color: #29323c;
    }

    .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected.ant-select-tree-node-selected {
        background-color: #29323c;
    }

    .ant-empty-description {
        color: $primary-color;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
        background-color: $secondary-bg;
        color: $primary-color;
        border: 1px solid #3b4652;

        .ant-select-selection__choice__remove {
            color: $primary-color;
        }
    }

    .ant-select-dropdown-menu-item-selected {
        background-color: $secondary-bg;
        color: $primary-color;
    }
}