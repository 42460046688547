@import '/src/styles/variables.scss';
@import '/src/styles/modal.scss';
@import '/src/styles/message.scss';
@import '/src/styles/slider.scss';
@import '/src/styles/dropdown.scss';
@import '/src/styles/notification.scss';
@import '/src/styles/table.scss';
@import '/src/styles/panel.scss';
@import '/src/styles/resilience.scss';

$tab-height: 70px;
$tabpane-width: 600px;
$tabpane-height: 490px;
$tabpane-offset-right: 25px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-color;
  }
}

.info-area {
  padding: 10px;
  z-index: 3;
  transition: 0.3s opacity ease-in-out;
  position: relative;

  .m-title {
    text-align: center;
    font-weight: bold;
    color: white !important;
    font-size: 10px;
    margin-bottom: 5px;
    padding: 0 10px;
  }

  .m-text {
    font-size: 30px;
    font-weight: bold;
    color: white !important;
  }


  .img-container {
    width: 40px;
    margin-right: 10px;

    .m-img {
      width: 100%;
    }

  }

  .info-indication {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 20px;
    border-radius: 50%;
    opacity: 0.3;
    z-index: -1;
  }
}

.img-marker-container {

  .m-img {
    width: 100%;
  }
}

.c-marker {
  z-index: 2;

  .m-title {
    text-align: center;
    color: white;
  }

  .marker-container {
    position: relative;
    // transition: 0.3s transform ease-in-out;

    .marker-indicator {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
}

.circle-marker {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 5px;

  img {
    width: 100%;
  }

}

.rectangle {
  width: 30px;
  height: 30px;

  img {
    width: 100%;
  }

  padding: 5px;
}

.rhombus {

  width: 30px;
  height: 30px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

  img {
    width: 100%;
  }

  padding: 5px;
}

.hexagon {
  width: 30px;
  height: 30px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  img {
    width: 100%;
  }
}

.scale-hov {
  // transition: 0.3s transform ease-in-out;

  &:hover {
    // transform: scale(1.2) !important;
  }
}


.mapboxgl-popup-tip {

  border-top-color: transparent !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {

  border-bottom-color: transparent !important;
}

.mapboxgl-popup {
  z-index: 10;
  padding-bottom: 20px;

  .mapboxgl-popup-content {
    border-radius: $min-border-radius;
    background-color: $primary-bg !important;
    border: 1px solid $border-color;
    width: 200px;
    pointer-events: auto !important;

    .popup-title {
      text-align: center;
      font-weight: 600;
      color: $primary-color;
    }

    .popup-body-child {
      color: $primary-color;
      display: flex;
      justify-content: space-between;

      div {
        &:nth-child(1) {
          color: white;
          margin-right: 5px;

          width: 90px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          width: 100px;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .popup-btn-show-more {
      text-align: center;
      font-style: italic;
      color: white;
      margin-top: 10px;
      text-decoration: underline;
    }

    .popup-btn-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      .popup-btn {
        border-radius: $min-border-radius;
        color: white;
        margin-bottom: 3px;
        padding: 5px;
        text-align: center;
        transition: 0.3s background ease-in-out;

        &:nth-child(1) {
          margin-top: 7px;
          background: #2c9668;

          &:hover {
            background: #227853;
          }
        }

        &:nth-child(2) {
          background: #6a7485;

          &:hover {
            background: #586273;
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;
}

.c-checkbox {
  transform: translateY(1px);

  .ant-form-item {
    margin: 0 !important;
    margin-left: 10px !important;
  }

  .ant-checkbox-inner {
    background-color: $secondary-bg !important;
    border: 1px solid $border-color !important;
  }
}


.resilience-title {
  color: $primary-color;
  text-align: center;
}

.ant-progress-text {
  color: $primary-color !important;
  font-size: 12px !important;
}

.ant-select-selection-selected-value {
  color: #bfbfbf;
}

.ant-checkbox-inner {
  background-color: $secondary-bg !important;
  border: 1px solid #656e79 !important;
}

.custom-pops {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: red;
  z-index: 9999;
}

.mapboxgl-popup-close-button {
  color: white;
}

.incident-marker {
  animation: gelatine 0.5s infinite;


}


@keyframes gelatine {

  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}


.mitigationTableContainer {
  background: $primary-bg;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: $min-border-radius;
  width: 250px;

  .mitigationTitle {
    padding: 15px 0;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .mitigationRows {
    padding-right: 5px;
    overflow: hidden;
    overflow-y: scroll;


    .mitigationRow {
      padding: 5px;
      background: $secondary-bg;
      border: 1px solid $border-color;
      color: $primary-color;
      margin-bottom: 5px;
      border-radius: $min-border-radius;

      div {
        &:nth-child(2) {
          font-size: 12px;
        }

        &:nth-child(1) {
          font-weight: bold;
        }
      }
    }
  }

  .mitigationDisclaimer {
    font-weight: 500;
    padding: 1em 0;
    font-size: 12px;
    font-style: italic;
  }
}

.cluster-marker {
  text-align: center;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.ant-dropdown-menu {
  background: $secondary-bg !important;
  
}

.ant-dropdown-menu-item{
  color: $primary-color !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover{
  background: $primary-bg !important;
}


.tabs {
  bottom: 30px;
  right: $tabpane-offset-right;
  position: absolute !important;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: $secondary-bg !important;
  // border: 1px solid $border-color !important;
  border-radius: $min-border-radius;
  padding: 14px;

}
.ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-tabs {
  // width: $tabpane-width;
  width: 25vw;
  height: 85vh;
  min-width: 400px;
  max-width: 545px;
}



.ant-tabs-bar{
  border-bottom: 0 !important;

}

// Tabs
.ant-tabs-tab {

  background-color: $secondary-bg !important;
  border-radius: 10px !important;
  filter: brightness(85%);
  width: $tab-height;
  height: $tab-height;
  opacity:0.4
  
}
.ant-tabs-tab-active.ant-tabs-tab{

  background-color: $secondary-bg !important;
  border-radius: 10px !important;
  filter: none;

  width: $tab-height + 20px;
  height: $tab-height + 20px;
  opacity:1 !important
  
}

div.ant-tabs-tab-active.ant-tabs-tab {
}

.ant-tabs-tab-active {
  fill: red;
}

// Tab Pane
.ant-tabs-tabpane {
  padding: 0px 8px;
}


.ant-tabs.ant-tabs-card .ant-tabs-card-bar div.ant-tabs-nav-container {
  height: $tab-height ;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar div.ant-tabs-tab {
  height: $tab-height;
  padding: 16px;

}



.ant-tabs .ant-tabs-tabpane{
  background-color: $secondary-bg;
  color: white;
  border-radius: 10px;
  height:$tabpane-height;

}
.ant-tabs-nav-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: $tab-height;


}
.ant-tabs-nav .ant-tabs-tab{
  margin: 0 13px 0 0 !important;

}
.ant-tabs-nav .ant-tabs-tab:last-child{
  margin-right:0 !important;
  
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
  border: 0 !important;
}

.tab-icon {
  width: 100%;
  height: 100%;
  vertical-align:middle; 
  fill: $primary-color ;

  &:hover {
    fill: white;
  }
  &:active {
    fill: #40a9ff;

  }
}

.ant-tabs-tab-active {
  & .tab-icon{
    fill: #40a9ff;
  }
}


// Card
.ant-card{
  border: none;
  background-color: $secondary-bg;

}

.ant-card.ant-card-bordered {
  background: none;
  border: none;
}
.ant-card-head-title {
  color: $primary-color;
}

.card-title{
  margin:none;
  padding: none;
}

.card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.add-anchor {
  line-height: 1;
  // &:hover .add-circle {
  //   fill: #40a9ff;
  // }

}
.add-circle {
  width: 19px;
  fill: $primary-color;
  height: 19px;

}

// Table
.ant-table-header.ant-table-hide-scrollbar {

  background: transparent

}

.ant-table-placeholder {
  background: $primary-color !important;
}

.ant-tag{
  background: $primary-bg !important;
  color: $primary-color !important;
  border: 1px solid $border-color !important;
}

.anticon-close{
  color: white !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 8px 7px !important;
}

.ant-table-selection-column {
  width: 40px !important
}
.ant-table-selection-col {
  width: 40px !important
}

.ant-pagination-simple-pager{
  color: $primary-color;
}

.ant-pagination-simple .ant-pagination-simple-pager input{
  background: $primary-bg !important;
  color: $primary-color !important;
  border: 1px solid $border-color !important;
}

.ant-table-body{
  max-height: 300px !important;
  background: $secondary-bg !important;
  
  table {
      border-spacing: 0 5px !important;
      background: transparent;
    }
}


.ant-table-row td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-table-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-table-thead>tr:first-child>th:first-child {
  border-top-left-radius: 0 !important;
}
.ant-table-thead>tr:first-child>th:last-child {
  border-top-right-radius: 0 !important;
}

.ant-popover-inner{
  background: $primary-bg !important;
  border: 1px solid $border-color !important;
  border-radius: 15px;
}

.ant-popover-message{
  color: $primary-color !important;
}

.ant-popover-buttons{

  .ant-btn{
    color: $primary-color !important;
    background: $primary-bg !important;
    border: 1px solid $border-color !important;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    background: $secondary-bg !important;
  }
}

.ant-dropdown{
  z-index: 300;
}

body,
html {
  overflow: hidden;
}