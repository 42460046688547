@import '/src/styles/variables.scss';

.ant-select-dropdown {
    * {
        ::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: $scrollbar-track-color;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: $scrollbar-thumb-color;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: $scrollbar-thumb-color;
        }
    }
}

body {
    .ant-select-dropdown {
        background-color: $primary-bg;

        .ant-select-dropdown-menu-item {
            color: $primary-color;

            &.ant-select-dropdown-menu-item-active {

                background-color: $secondary-bg;
            }
        }
    }
}