@import '/src/styles/variables.scss';

body {

    .ant-table-thead tr th {
        background-color: #2f3944 !important;
        border-bottom: 1px solid $border-color !important;
        color: $primary-hover-color;
    }

    .ant-card-head {
        border-bottom: 1px solid $primary-color;
    }
    


    .ant-table-tbody tr td {
        border-color: $border-color !important;
        background-color: $primary-bg !important;
        border-bottom: 0 !important;
    }



    .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
    .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
        background: $border-color !important;
    }

    .ant-table {
        color: $primary-color !important;
    }

    .ant-pagination-total-text {
        color: $primary-color !important;
    }


    .ant-pagination-item-link {
        background-color: $secondary-bg !important;
        color: $primary-color !important;
        border: 1px solid $border-color !important;
    }

    .ant-pagination-item-active {
        background-color: $primary-bg !important;
        border: 1px solid $border-color !important;



    }

    .ant-pagination-item-active:focus {
        a {
            color: $primary-color ;
        }
    }

    .ant-table-placeholder {
        background: #2f3944 !important;
        border: 1px solid transparent !important;
        border-top-color: $border-color !important ;
    }

    .ant-pagination-item {
        background-color: $secondary-bg ;
        color: $primary-color ;
        border: 1px solid $border-color ;

        &:hover {
            border-color: $active-border-color;

            a {
                color: $primary-color ;
            }
        }

        a {
            color: $primary-color ;
        }
    }
    * ::-webkit-scrollbar-track {
        background: #2f3944 !important;
    }

    * ::-webkit-scrollbar-thumb {
        background: $primary-bg !important;

    }
}