@import '/src/styles/variables.scss';
.tab-resilience {
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
}

.tab-resilience-live {
    display: flex;
    flex-direction: row;
    width: 75%;
    margin-top: 25px;
    margin-left:5px
}

.tab-resilience div.resilience-title {
    padding-top:1px;
    white-space: nowrap;
    padding-right: 12px;
    color: $primary-color;
}

.tab-resilience-live div.resilience-title-live{
    padding-top: 1px;
    white-space: nowrap;
    padding-right: 12px;
    color: $primary-color;
}
.resilience-title-live{
    width:75%
}